import { graphql, Link } from 'gatsby'
import { sampleSize } from 'lodash';
import React from 'react'

import Layout from '../components/layout'
import { FluxibleIconBlock } from '../components/FluxibleIcon'
import PostSummary from '../components/PostSummary';
//import SpeakerSummary from '../components/SpeakerSummary';

import Sponsors from '../components/sponsors';

export default class IndexPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      speakers: null,
      sponsors: null,
      headerImages: [
        { url: `${process.env.GATSBY_MEDIA_URL}/media/flux-header-4.jpg` },
      ]
    }
  }

  componentDidMount() {
    const { data } = this.props;

    let { edges: speakers } = data.speakers || { edges: [] }
    let { edges: sponsors } = data.sponsors || { edges: [] }

    const headerImages = this.state.headerImages.concat([
      { url: `${process.env.GATSBY_MEDIA_URL}/media/flux-header-1.jpg` },
      { url: `${process.env.GATSBY_MEDIA_URL}/media/flux-header-2.jpg` },
      { url: `${process.env.GATSBY_MEDIA_URL}/media/flux-header-3.jpg` }
    ])

    speakers = speakers.filter(s => s.node.events && s.node.events.some(e => e.eventType.name.includes('Conference')))
    speakers = sampleSize(speakers, 3);
    this.setState({ speakers, sponsors, headerImages });

    this.currentImg = 0
    this.interval = setInterval(() => requestAnimationFrame(this.imageScheduler), 5000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  // This function changes opacities for the banner images
  // This facilitates the fade transition
  imageScheduler = () => {
    const index = this.currentImg
    for (let i = 0; i < this.state.headerImages.length; i += 1) {
      const image = document.querySelector(`.img${i}`)
      if (image !== null) {
        image.style.opacity = index === i ? 0 : 1
      }
    }
    this.currentImg = index === this.state.headerImages.length - 1 ? 0 : index + 1
    this.shuffleImages(index)
  }

  // This function re-orders the z-indexes of the banner images
  // This ensures that the fade occurs between the correct images
  // The timeout ensure that the images are re-ordered only after the fade is finished
  shuffleImages = (index) => {
    setTimeout(() => {
      requestAnimationFrame(() => {
        for (let i = 0; i < this.state.headerImages.length; i += 1) {
          const image = document.querySelector(`.img${i}`)
          if (image !== null) {
            image.style.zIndex = index === i ? `0` : `${parseInt(image.style.zIndex) + 1}`
          }
        }
      })
    }, 2000);
  }

  render() {
    const { data } = this.props
    const { edges: posts } = data.posts
    const { edges: testimonials } = data.testimonials || { edges: [] }

    const edition = data.edition

    //this.imageScheduler(0)

    return (
      <Layout>
        <div className="fluxible-banner">
          <div className="banner-container" aria-hidden="true">
            {this.state.headerImages.map((img, idx) => (
              <div key={idx} className={`banner-image img${idx}`} aria-hidden="true" style={{ backgroundImage: `url(${img.url})`, zIndex: `${this.state.headerImages.length - idx}` }}></div>
            ))}
          </div>

          <div className="banner-text-container">
            <h1 className="xl-heading">Canada’s UX Festival</h1>
            <h2 className="date-heading">Fluxible will return in 2023</h2>
            <a href="/2022/03/fluxible-is-taking-a-break-in-2022/" className="cta-link">See our Fluxible 2022 announcement</a>
          </div>
        </div>
        {/* This section temporarily hidden for Fluxible hiatus March 2022
        <section className="section dark">
          <div className="homepage-section text-only">
            <h2 className="text-center">A week-long celebration of user experience</h2>
            <h3 className="subtitle text-center">at venues across Waterloo Region, Ontario</h3>

            <div className="box-with-icon-grid">

              <div className="box-with-icon-item">
                <div className="fluxible-icon"><FluxibleIconBlock icon="cog" size="sm" /></div>
                <h4 className="box-with-icon-title">Fluxible Mini Broadcasts</h4>
                <h5 className="box-with-icon-date">June 7, 8, 9, & 11, 2021</h5>
                <p>Small broacast events featuring UX, and related, speakers on a variety of topics. Bite-sized portions to get you through your day!</p>
                <p className="box-with-icon-cta"><Link to={`${edition.fields.slug}program-tv`}>See the program</Link></p>
              </div>

              <div className="box-with-icon-item">
                <div className="fluxible-icon"><FluxibleIconBlock icon="microphone" size="sm" /></div>
                <h4 className="box-with-icon-title">Fluxible Big Broadcast</h4>
                <h5 className="box-with-icon-date">June 10, 2021</h5>
                <p>The Big Broadcast, with presentations featuring UX, and related, speakers on a variety of topics. Plus music and more!</p>
                <p className="box-with-icon-cta"><Link to={`${edition.fields.slug}program-tv`}>See the program</Link></p>
              </div>

            </div>
          </div>
        </section>*/}

        <section className="section">
          <div className="home-news-section">
            <h2 className="text-center">News and announcements</h2>
            <div className="home-news-section-posts">
              {posts.map(({ node: post }) => (
                <PostSummary key={post.id} post={post} cellSize="small-12 medium-4" />
              ))}
            </div>
          </div>
        </section>

        {/*  // This code has not been updated for the 2020 design

        <section className="section grid-container">
          <div className="homepage-section">
            <h2 className="text-center">{edition.name} Speakers</h2>
            <div className="grid-x grid-margin-x speakers-grid">
              {this.state.speakers && this.state.speakers.map(({ node: speaker }) => (
                <SpeakerSummary key={speaker.id} speaker={speaker} />
              ))}
            </div>
            <p className="text-center"><Link to={`${edition.fields.slug}/speakers`}>More speakers...</Link></p>
          </div>
        </section>
              
        */}

        <section className="section darkest">
          <h2 className="text-center">“The entire experience is truly amazing”</h2>
          <div className="testimonial-grid">
            <div className="cell small-12 medium-4 text-center" style={{ marginBottom: '3rem' }}>
              <div className="responsive-embed widescreen">
                <iframe title="Fluxible conference 2017" width="560" height="315" src="https://www.youtube-nocookie.com/embed/leEzJtGY260" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </div>
            </div>
            <div className="cell small-12 medium-4 text-center" style={{ marginBottom: '3rem' }}>
              <div className="responsive-embed widescreen">
                <iframe title="Fluxible 2015 conference a success" width="560" height="315" src="https://www.youtube-nocookie.com/embed/J3tlVsGxMz8" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </div>
            </div>
            <div className="cell small-12 medium-4 text-center" style={{ marginBottom: '3rem' }}>
              <div className="responsive-embed widescreen">
                <iframe title="Fluxible 2014 highlights" width="560" height="315" src="https://www.youtube-nocookie.com/embed/RLJ5btpjTuM" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </div>
            </div>
            {testimonials.map(({ node: blurb }) => (
              <div className="cell small-12 medium-4 testimonial-item" key={blurb.id}>
                <span aria-hidden="true"></span>
                <blockquote>{blurb.content}</blockquote>
              </div>
            ))}
          </div>
          <p className="text-center more-testimonials"><Link to="/testimonials">More from our attendees and speakers...</Link></p>
        </section>

        <section className="section homepage-sponsors-section">
          <h2 className="text-center">Thanks to our {edition.startDate.substring(0, 4)} sponsors</h2>
          <Sponsors data={this.state.sponsors} template='homepage' />
        </section>

      </Layout >
    )
  }
}

export const indexPageQuery = graphql`
  query IndexPageQuery($currentEditionId: Int!, $editionTypeId: Int!) {
    speakers: allFluxibleSpeakers(
      filter: { editionId: { eq: $currentEditionId }, name: { ne: "" } }
    ) {
      edges {
        node {
          id
          name
          jobPosition
          fields {
              slug
          }
          photo {
              name
              altText
              contentType
            }
          events {
            eventType {
              name
            }
          }
        }
      }
    }
    posts: allFluxibleBlogPosts(
      filter:{title:{ne:""}, editionTypeId: {eq: $editionTypeId}},
      sort: {fields: [publishedOn], order: DESC},
      limit: 3
    ) {
      edges {
        node {
          id
          title
          summary
          content
          publishedOn
          fields {
            slug
          }
          featuredImage {
            name
            altText
            contentType
          }
        }
      }
    }
    testimonials: allFluxibleTestimonials(
        filter: { content: { ne: "" } },
        limit: 3
      ) {
      edges {
        node {
            id
            content
        }
      }
    }
    edition: fluxibleEditions(alternative_id: { eq: $currentEditionId }
      ) {
      name
      startDate
      endDate
      editionTypeId
      fields{
        slug
      }
    }
    sponsors: allFluxibleSponsors(
      filter: { editionId: { eq: $currentEditionId } },
      sort: {fields: [name], order: ASC},
    ) {
      edges {
        node {
          name
          sponsorTypeId
          logo {
            name
            contentType
          }
          socialMedia {
            type
            value
          }
        }
      }
    }
  }
`
